import React, { useState } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Axios from 'axios';

const AddCategory = ({ fetchCategories }) => {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [categoryData, setCategoryData] = useState({
    name: '',
    description: '',
    isHome: false,
  });
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCategoryData({
      ...categoryData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post(
        `${appBaseUrl}/api/categories/addCategory`,
        categoryData
      );
      if (data?._id) {
        toast.success('Category added successfully!');
        setCategoryData({ name: '', description: '', isHome: false });
        handleCloseModal(); // Corrected function name
        fetchCategories(); // Refresh categories after adding
      }
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="add-category text-end" style={{ marginBottom: '20px' }}>
      <Button variant="primary" onClick={handleShowModal}>
        Add Category
      </Button>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="formCategoryName">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={categoryData.name}
                    onChange={handleChange}
                    placeholder="Enter category name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="formCategoryDescription">
                  <Form.Label>Category Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={categoryData.description}
                    onChange={handleChange}
                    placeholder="Enter category description"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="formCategoryIsHome">
                  <Form.Check
                    type="checkbox"
                    name="isHome"
                    label="Display on Home Page"
                    checked={categoryData.isHome}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Add Category
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddCategory;

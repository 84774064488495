import React from 'react';
import { Card } from 'react-bootstrap';

function ArticleExcerpt({ description }) {
  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const trimText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;

    const trimmedText = text.substr(0, maxLength);
    return `${trimmedText.substr(
      0,
      Math.min(trimmedText.length, trimmedText.lastIndexOf(' '))
    )}...`;
  };

  const getExcerpt = (htmlContent, maxLength) => {
    const plainText = stripHtmlTags(htmlContent);
    return trimText(plainText, maxLength);
  };

  return <Card.Text>{getExcerpt(description, 200)}</Card.Text>;
}

export default ArticleExcerpt;

import React, { useState, useEffect, useCallback } from 'react';
import { Table, Container, Button, Pagination, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { getError } from '../../Utils';
import { useNavigate } from 'react-router-dom';

const ArticleListing = () => {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const limit = 10; // Number of articles per page

  // Fetch articles from backend
  const fetchArticles = useCallback(
    async (page = 1) => {
      setLoading(true); // Start loading
      try {
        const { data } = await Axios.get(
          `${appBaseUrl}/api/articles/admin-article?page=${page}&limit=${limit}`
        );
        console.log(data);
        setArticles(data?.articles);
        setCurrentPage(data.currentPage);
        setTotalPages(data.totalPages);
        setLoading(false); // Stop loading
      } catch (error) {
        toast.error(getError(error));
        setLoading(false); // Stop loading in case of error
      }
    },
    [appBaseUrl]
  ); // Include appBaseUrl as a dependency

  useEffect(() => {
    fetchArticles(currentPage);
  }, [fetchArticles, currentPage]);

  // Delete article function
  const handleDelete = async (article) => {
    if (window.confirm(`Are you sure you want to delete "${article.name}"?`)) {
      try {
        const response = await Axios.delete(
          `${appBaseUrl}/api/articles/delete/${article._id}`
        );
        if (response.status === 200) {
          toast.success(`Article "${article.name}" deleted successfully!`);
          fetchArticles(); // Refresh articles after deletion
        }
      } catch (error) {
        console.error('Error deleting article:', error);
        toast.error(getError(error));
      }
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Render pagination controls
  const renderPagination = () => (
    <Pagination>
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
      />
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      />
      {Array.from({ length: totalPages }, (_, i) => (
        <Pagination.Item
          key={i + 1}
          active={i + 1 === currentPage}
          onClick={() => handlePageChange(i + 1)}
        >
          {i + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      />
      <Pagination.Last
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(totalPages)}
      />
    </Pagination>
  );

  return (
    <Container id="content" fluid>
      <h1>Article Listing</h1>
      <div className="text-end">
        <Button
          variant="primary"
          onClick={() => navigate(`/admin/add-article/`)}
          style={{ marginBottom: '20px' }}
        >
          Add Article
        </Button>
      </div>
      {loading ? ( // Display loading spinner when fetching data
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Title</th>
                <th style={{ width: '11%' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article, index) => (
                <tr key={article._id}>
                  <td>{(currentPage - 1) * limit + index + 1}</td>
                  <td>{article.name}</td>
                  <td>
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() =>
                        navigate(`/admin/edit-article/${article?._id}`)
                      }
                    >
                      Edit
                    </Button>
                    &nbsp;
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(article)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-4">
            {renderPagination()}
          </div>
        </>
      )}
    </Container>
  );
};

export default ArticleListing;

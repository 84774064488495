import React, { useEffect, useState, useCallback } from 'react';
import { Table, Container, Button, Pagination, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { getError } from '../../Utils';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';

const CategoryListing = () => {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [totalPages, setTotalPages] = useState(1); // Total pages from server
  const [loading, setLoading] = useState(false); // Loading state
  const limit = 10; // Number of categories per page

  const fetchCategories = useCallback(
    async (page = 1) => {
      setLoading(true); // Start loading
      try {
        const { data } = await Axios.get(
          `${appBaseUrl}/api/categories/admin-categories`
        );
        setCategories(data.categories); // Update categories with paginated data
        setCurrentPage(data.currentPage); // Set current page
        setTotalPages(data.totalPages); // Set total number of pages
        setLoading(false); // Stop loading
      } catch (err) {
        toast.error(getError(err));
        setLoading(false); // Stop loading on error
      }
    },
    [appBaseUrl]
  );

  useEffect(() => {
    fetchCategories(currentPage);
  }, [fetchCategories, currentPage]);

  const handleDelete = async (category) => {
    if (
      window.confirm(
        `Are you sure you want to delete category "${category.name}"?`
      )
    ) {
      try {
        const response = await Axios.delete(
          `${appBaseUrl}/api/categories/delete/${category._id}`
        );
        if (response.status === 200) {
          // Optionally show a success message or update state
          fetchCategories(); // Refresh categories after deletion
        }
      } catch (error) {
        console.error('Error deleting category:', error);
        // Handle error, show error message, etc.
      }
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Render pagination controls
  const renderPagination = () => (
    <Pagination>
      <Pagination.First
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
      />
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      />
      {Array.from({ length: totalPages }, (_, i) => (
        <Pagination.Item
          key={i + 1}
          active={i + 1 === currentPage}
          onClick={() => handlePageChange(i + 1)}
        >
          {i + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      />
      <Pagination.Last
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(totalPages)}
      />
    </Pagination>
  );

  return (
    <Container id="content" fluid>
      <h1>Category Listing</h1>
      <AddCategory fetchCategories={fetchCategories} />
      {loading ? ( // Show spinner while loading
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th style={{ width: '7%' }}>Is Home</th>
                <th style={{ width: '11%' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.length > 0 ? (
                categories?.map((category, index) => (
                  <tr key={category._id}>
                    <td>{(currentPage - 1) * limit + index + 1}</td>
                    <td>{category.name}</td>
                    <td>{category.isHome ? 'Yes' : 'No'}</td>
                    <td>
                      <EditCategory
                        fetchCategories={fetchCategories}
                        category={category}
                      />{' '}
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(category)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No categories found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-4">
            {renderPagination()}
          </div>
        </>
      )}
    </Container>
  );
};

export default CategoryListing;

import React from 'react';

const Users = ({ children }) => {
  return (
    <div id="content">
      <div className="midde_cont">
        <div className="container-fluid"></div>
      </div>
    </div>
  );
};

export default Users;

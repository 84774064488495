import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Navbar as AdminNavbar,
  Header as AdminHeader,
  Footer as AdminFooter,
  Dashboard,
  ArticleListing,
  CategoryListing,
  Users,
  AddArticle,
  EditArticle,
  Nopage,
} from './components/admin';

import {
  Header as FrontendHeader,
  Footer as FrontendFooter,
  Home,
  About,
  Contact,
  Login,
  Signup,
  ArticleDetail,
  CategoryDetail,
} from './components/frontend';

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (isAdminRoute) {
      import('./admin.css');
    } else {
      import('./assets/css/front.css');
      import('./responsive.css');
    }
  }, [isAdminRoute]);

  // Define route-to-class mapping
  const routeClassMapping = {
    '/contact-us': 'contact',
    '/articles/:id': 'article',
    '/category/:id': 'category',
    // Add more mappings as needed
  };

  // Function to get the class name based on the current route
  const getClassNameForRoute = () => {
    for (const [path, className] of Object.entries(routeClassMapping)) {
      const regex = new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`);
      if (regex.test(location.pathname)) {
        return className;
      }
    }
    return '';
  };

  const additionalClass = getClassNameForRoute();

  // Check if the user is logged in
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo?.token) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
      if (isAdminRoute) {
        navigate('/login'); // Redirect to login if accessing admin without login
      }
    }
  }, [isAdminRoute, navigate]);

  return (
    <div className="app">
      <ToastContainer position="bottom-center" limit={1} />
      {isAdminRoute ? <AdminNavbar /> : null}
      {isAdminRoute ? <AdminHeader /> : <FrontendHeader />}
      <main className={`main-content ${additionalClass}`}>
        <Routes>
          {isAdminRoute ? (
            isLogin ? (
              <>
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/admin/add-article" element={<AddArticle />} />
                <Route
                  path="/admin/edit-article/:id"
                  element={<EditArticle />}
                />
                <Route path="/admin/articles" element={<ArticleListing />} />
                <Route path="/admin/categories" element={<CategoryListing />} />
                <Route path="/admin/users" element={<Users />} />
                <Route path="*" element={<Nopage />} />
              </>
            ) : (
              // Redirect to login if not authenticated
              <Route path="*" element={navigate('/login')} />
            )
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/articles/:id" element={<ArticleDetail />} />
              <Route path="/category/:id" element={<CategoryDetail />} />
              <Route path="*" element={<Nopage />} />
            </>
          )}
        </Routes>
      </main>
      {isAdminRoute ? <AdminFooter /> : <FrontendFooter />}
    </div>
  );
};

const App = () => (
  <Router>
    <Layout />
  </Router>
);

export default App;

import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Axios from 'axios';

const EditCategory = ({ fetchCategories, category }) => {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [categoryData, setCategoryData] = useState({
    name: '',
    description: '',
    isHome: false,
  });
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCategoryData({
      ...categoryData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.put(`${appBaseUrl}/api/categories/edit`, {
        _id: category._id, // Include the _id of the category being updated
        ...categoryData, // Include the rest of the category data
      });
      if (data?._id) {
        toast.success('Category added successfully!');
        setCategoryData({ name: '', description: '', isHome: false });
        handleCloseModal(); // Corrected function name
        fetchCategories(); // Refresh categories after adding
      }
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    }
  };

  useEffect(() => {
    setCategoryData({
      name: category.name,
      description: category.description,
      isHome: category.isHome,
    });
  }, [category]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Button variant="warning" size="sm" onClick={handleShowModal}>
        Edit
      </Button>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdate}>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="formCategoryName">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={categoryData.name}
                    onChange={handleChange}
                    placeholder="Enter category name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="formCategoryDescription">
                  <Form.Label>Category Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    value={categoryData.description}
                    onChange={handleChange}
                    placeholder="Enter category description"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group controlId="formCategoryIsHome">
                  <Form.Check
                    type="checkbox"
                    name="isHome"
                    label="Display on Home Page"
                    checked={categoryData.isHome}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Edit Category
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCategory;

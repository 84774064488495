import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Dashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo?.token) {
      navigate('/login');
    }
  }, [navigate]);
  return (
    <Container id="content" fluid>
      <Row className="column_title">
        <Col md={12}>
          <Card className="page_title">
            <Card.Body>
              <Card.Title as="h2">Dashboard</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="column1">
        <Col md={6} lg={3}>
          <Card className="counter_section mb-3">
            <Card.Body className="text-center">
              <Card.Text>
                <i className="fa fa-user yellow_color"></i>
              </Card.Text>
              <Card.Title as="h3" className="total_no">
                250
              </Card.Title>
              <Card.Text className="head_couter">Categories</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={3}>
          <Card className="counter_section mb-3">
            <Card.Body className="text-center">
              <Card.Text>
                <i className="fa fa-clock-o blue1_color"></i>
              </Card.Text>
              <Card.Title as="h3" className="total_no">
                123.50
              </Card.Title>
              <Card.Text className="head_couter">Visitors</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={3}>
          <Card className="counter_section mb-3">
            <Card.Body className="text-center">
              <Card.Text>
                <i className="fa fa-cloud-download green_color"></i>
              </Card.Text>
              <Card.Title as="h3" className="total_no">
                1,805
              </Card.Title>
              <Card.Text className="head_couter">Articles</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={3}>
          <Card className="counter_section mb-3">
            <Card.Body className="text-center">
              <Card.Text>
                <i className="fa fa-comments-o red_color"></i>
              </Card.Text>
              <Card.Title as="h3" className="total_no">
                54
              </Card.Title>
              <Card.Text className="head_couter">Comments</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;

import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <div className="page contact">
      <Helmet>
        <title>Lekh - Contact Us</title>
      </Helmet>
      <Container className="my-4">
        If you have any query or any issue feel free to wite us on email{' '}
        <Link to="mailto:lekhjunction@gmail.com">lekhjunction@gmail.com</Link>
      </Container>
    </div>
  );
}

export default Contact;

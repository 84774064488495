import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function About() {
  return (
    <div className="about">
      <Helmet>
        <title>Lekh - About Us</title>
      </Helmet>
      <Container className="my-4">
        <div style={{ whiteSpace: 'pre-line' }}>
          <p>
            जितना हमारे साथ वक़्त बिताओगे उतने हम समझ आते जाएँगे । वो कहते हैं ना
            कि कुछ करने की ठान लो तो बस उस काम के लिए निकल पड़ो, काफिले अपने आप
            बन जाएंगे । हम भी आप लोगों में से हैं जो थोड़ा
            लिखने-पड़ने के शौकीन हैं| हम से कुछ कहना चाहते हैं तो बेजिझक हम को
            ईमेल कीजिए lekhjunction@gmail.com पर |
          </p>
        </div>
      </Container>
    </div>
  );
}

export default About;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <Container fluid>
      <Row>
        <Col className="footer text-center">
          <p>
            Copyright © 2018 Designed by cityjust. All rights reserved.
            <br />
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Nav,
  NavDropdown,
  Image,
  Badge,
  Button,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
  const [name, setName] = useState('John David');
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    setName(userInfo?.name);
  }, []);

  return (
    <div className="topbar">
      <Navbar expand="lg" variant="light" bg="dark">
        <div className="full">
          <Button
            variant="dark"
            id="sidebarCollapse"
            className="sidebar_toggle"
          >
            <i className="fa fa-bars"></i>
          </Button>
          <Navbar.Brand as={Link} to="/" className="logo_section">
            <Image
              className="img-responsive"
              src="../images/logo.png"
              alt="#"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="right_topbar">
            <Nav className="ms-auto icon_info">
              <Nav.Link as={Link} to="#">
                <i className="fa fa-bell-o"></i>
                <Badge bg="secondary">2</Badge>
              </Nav.Link>
              <Nav.Link as={Link} to="#">
                <i className="fa fa-question-circle"></i>
              </Nav.Link>
              <Nav.Link as={Link} to="#">
                <i className="fa fa-envelope-o"></i>
                <Badge bg="secondary">3</Badge>
              </Nav.Link>
              <NavDropdown
                title={
                  <>
                    <Image
                      className="img-responsive rounded-circle"
                      src="../images/user_img.jpg"
                      alt="#"
                      width="30"
                      height="30"
                    />
                    <span className="name_user">{name}</span>
                  </>
                }
                id="user-profile-dropdown"
              >
                <NavDropdown.Item as={Link} to="profile.html">
                  My Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="settings.html">
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="help.html">
                  Help
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="#">
                  <span>Log Out</span> <i className="fa fa-sign-out"></i>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
}

export default Header;

import React, { useContext } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Store } from '../../Store';

function Header() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const singoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
  };
  return (
    <Navbar bg="light" expand="lg" className="header lekh">
      <Container>
        <Navbar.Brand to="/">
          <Nav.Link as={Link} to="/">
            <img src="/images/logo.png" className="logo" alt="Logo" />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about-us">
              About Us
            </Nav.Link>
            <NavDropdown title="Categories" id="basic-nav-dropdown">
              <NavDropdown.Item
                as={Link}
                to="/category/66a4cfc1c9450dc15168be0c"
              >
                Health
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/category/668ac52d13e122aa76edaa8b"
              >
                Editor's Choice
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/category/668ac5ca13e122aa76edaa8d"
              >
                Apna Gyaan
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                as={Link}
                to="/category/668ac5d413e122aa76edaa8f"
              >
                Parenting
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/category/66a4f1776c867b8a4c8e7889"
              >
                Bollywood Time Pass
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/contact-us">
              Contact Us
            </Nav.Link>
            {userInfo ? (
              <NavDropdown title={userInfo?.name} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/profile">
                  User Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/dashboard">
                  Dashboard
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={Link}
                  to="#signout"
                  onClick={singoutHandler}
                >
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link as={Link} to="/login">
                Sign In
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

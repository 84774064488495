import React, { useState, useEffect } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import axios from 'axios';

const ImageUpload = ({
  handleFileChange,
  setImageUrl,
  fileInputRef,
  resetProgressBarRef,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file);
    }
    handleFileChange(e);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'wcpnuesk'); // replace with your upload preset

    try {
      const response = await axios.post(
        'https://api.cloudinary.com/v1_1/daqe8jjzi/image/upload',
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );
      setImageUrl(response.data.secure_url);
    } catch (error) {
      console.error(
        'Error uploading the image',
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    if (resetProgressBarRef) {
      resetProgressBarRef.current = () => setUploadProgress(0);
    }
  }, [resetProgressBarRef]);

  return (
    <Form.Group controlId="formImage">
      <Form.Label>Article Image</Form.Label>
      <Form.Control
        type="file"
        name="image"
        onChange={handleChange}
        ref={fileInputRef}
      />
      {uploadProgress > 0 && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          className="mt-2"
        />
      )}
    </Form.Group>
  );
};

export default ImageUpload;
